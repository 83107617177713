import { createContext, useEffect, useState } from 'react';
import { Provider as JotaiProvider } from 'jotai';
import { NextComponentType } from 'next';
import { Session } from 'next-auth';
import { SessionProvider } from 'next-auth/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import NextNProgress from 'nextjs-progressbar';
import { pdfjs } from 'react-pdf';
import { Toaster } from 'sonner';
import { GoogleAnalytics } from '@/components/Analytics';
import Branch from '@/components/Branch/Branch';
import Cookie from '@/components/Cookie';
import ErrorBoundary from '@/components/ErrorBoundary';
import GoogleTranslateBoundary from '@/components/ErrorBoundary/GoogleTranslateBoundary';
import ErrorToasterListener from '@/components/ErrorToasterListener';
import GoSquared from '@/components/GoSquared/GoSquared';
import HotJar from '@/components/HotJar';
import GoogleTranslateWarningModal from '@/components/Modals/GoogleTranslateWarning';
import OpenInPopupPushListener from '@/components/OpenInPopupPushListener';
import ServiceAccountProjectListener from '@/components/ServiceAccountProjectListener';
import UseConnection from '@/components/hook/useConnection';
import { IS_PRODUCTION, IS_STAGE_SERVER } from '@/config/Constants';
import NotificationsProvider from '@/services/notifications.service';
import DarkSMATMuiTheme from '@/themes/muiDarkTheme';
import theme from '@/themes/muiTheme';
import { useDarkMode, usePreviousRoute } from '@/utils/hooks';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// External libraries css
import '../styles/globals.css';
import createEmotionCache from '../themes/createEmotionCache';
import 'quill/dist/quill.snow.css';
import 'react-phone-number-input/style.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

if (IS_PRODUCTION || IS_STAGE_SERVER) {
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.error('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.error(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      // eslint-disable-next-line prefer-rest-params
      return originalInsertBefore.apply(this, arguments);
    };
  }
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export type CustomAppProps = AppProps & {
  Component: NextComponentType & {
    public?: boolean;
    unsigned?: boolean;
    forceLightMode?: boolean;
    forceUnmount?: boolean;
    isIframe?: boolean;
  };
  emotionCache: EmotionCache;
  pageProps: { session?: Session };
};

export const ThemeContext = createContext(null);
export const RouterContext = createContext('/');

function getSelectedTheme(isDarkMode: boolean) {
  if (isDarkMode) {
    document?.documentElement?.classList?.add('dark');
  } else {
    document?.documentElement?.classList?.remove('dark');
  }
  return isDarkMode ? DarkSMATMuiTheme : theme;
}

export default function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps: { session, ...pageProps },
}: CustomAppProps) {
  const [loaded, setLoaded] = useState(false);
  const [isDarkMode, setDarkMode] = useDarkMode();
  const { isReady, pathname, asPath } = useRouter();
  const prevRoute = usePreviousRoute();

  useEffect(() => {
    if (isReady) {
      setLoaded(true);
    }
  }, [isReady]);

  if (!loaded) return null;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=2, shrink-to-fit=no"
        />
      </Head>
      <CacheProvider value={emotionCache}>
        <UseConnection />
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={Component.forceLightMode ? theme : getSelectedTheme(isDarkMode)}>
            <CssBaseline />
            {Component.isIframe ? (
              <Component key={pathname} {...pageProps} />
            ) : (
              <>
                <GoogleTranslateBoundary>
                  <GoogleTranslateWarningModal />
                </GoogleTranslateBoundary>
                <SessionProvider session={session}>
                  <RouterContext.Provider value={prevRoute}>
                    <JotaiProvider>
                      <ThemeContext.Provider value={[isDarkMode, setDarkMode]}>
                        <GoSquared />
                        <GoogleAnalytics />
                        <Cookie />
                        <HotJar />
                        <ErrorToasterListener />
                        <ServiceAccountProjectListener />
                        <ErrorBoundary>
                          <NextNProgress color="#178097" />
                          {/* public pages should be tagged with `public=true` flag */}
                          {Component.public ? (
                            <Component key={pathname} {...pageProps} />
                          ) : (
                            <OpenInPopupPushListener>
                              <NotificationsProvider>
                                {/* asPath as key here forces unmounting component if route change but parentRoute stays the same */}
                                {/* https://nextjs.org/docs/api-reference/next/router */}
                                <Component
                                  key={Component.forceUnmount ? asPath : pathname}
                                  {...pageProps}
                                />
                              </NotificationsProvider>
                            </OpenInPopupPushListener>
                          )}
                        </ErrorBoundary>
                        <Toaster position="top-right" toastOptions={{ duration: 5000 }} />
                        <Branch />
                      </ThemeContext.Provider>
                    </JotaiProvider>
                  </RouterContext.Provider>
                </SessionProvider>
              </>
            )}
          </ThemeProvider>
        </StyledEngineProvider>
      </CacheProvider>
    </>
  );
}
